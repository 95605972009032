import { QUERY_KEYS } from "@/enums";
import useExposureTrack from "@/hooks/useExposureTrack";
import { RecommendPage } from "@/types/jobs";
import { GetServerSidePropsContext } from "next";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { localStorageUtil } from "@/utils";
import { getIsSSRMobile } from "@/utils/getIsSsrMobile";
import { getRandomLinks, getRecommendLinks } from "@/utils/jobs/getRecommendLinks";

const PCLandingPage = dynamic(() => import("@/components/Landing"));
const MobileLandingPage = dynamic(() => import("@/components/MobileLanding"));

const structuredData = {
  "@context": "https://schema.org",
  "@type": "VideoObject",
  name: "Jobright: Your personal AI job search copilot",
  description: `Jobright is your personal AI job search copilot that transform the way you do job search from solo, time-consuming efforts to a copiloted journey with a seasoned AI career assistant at the helm, simplifying every job search step and accelerating your route to the best job outcomes.`,
  thumbnailUrl: [`https://img.youtube.com/vi/f2oQsk2er0Y/mqdefault.jpg`],
  uploadDate: "2024-06-20",
  duration: "PT33S",
  contentUrl: "https://www.youtube.com/watch?v=f2oQsk2er0Y",
  embedUrl: "https://www.youtube.com/watch?v=f2oQsk2er0Y",
};

type PCLandingProps = {
  isMobile: boolean;
  recommendLinks: RecommendPage[];
};

const LandingPage = ({ recommendLinks, isMobile }: PCLandingProps) => {
  const router = useRouter();
  useExposureTrack(isMobile ? "mobile_landing_page_exposure" : "landing_exposure_v3", {}, [
    QUERY_KEYS.INVITER_ID,
    QUERY_KEYS.UTM_SOURCE,
    QUERY_KEYS.UTM_CAMPAIGN,
  ]);

  useEffect(() => {
    if (router?.query[QUERY_KEYS.UTM_CAMPAIGN]) {
      localStorageUtil.set(
        QUERY_KEYS.UTM_CAMPAIGN,
        router?.query[QUERY_KEYS.UTM_CAMPAIGN] as string
      );
    }

    if (router?.query[QUERY_KEYS.UTM_SOURCE]) {
      localStorageUtil.set(QUERY_KEYS.UTM_SOURCE, router?.query[QUERY_KEYS.UTM_SOURCE] as string);
    }
  }, [router?.query]);

  if (isMobile) {
    return <MobileLandingPage recommendLinks={recommendLinks} />;
  } else {
    return <PCLandingPage />;
  }
};

LandingPage.getLayout = (page: React.ReactElement): React.ReactElement => {
  return (
    <>
      <Head>
        <link rel="canonical" href={`https://jobright.ai`} />

        <title>Jobright: Your AI Job Search Copilot</title>
        <meta
          name="description"
          content="Discover your dream job with Jobright, your AI job search copilot. Find relevant opportunities tailored to your goals. Gain filtered matches, deep insights, and stay ahead with real-time company trends. Let Jobright find your perfect fit."
          key="description"
        />
        <script
          key="structured-data"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
        />
      </Head>
      {page}
    </>
  );
};

export default LandingPage;

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const isMobile = getIsSSRMobile(context);
  return {
    props: {
      isMobile,
      recommendLinks: isMobile ? [...getRecommendLinks(10000, 30), ...getRandomLinks(20)] : [],
    },
  };
}
