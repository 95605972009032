import useMount from "ahooks/lib/useMount";

import { isEmpty } from "@/utils";
import { isBrowser } from "@/utils/browser";
import { trackEvent } from "@/utils/trace";

const useExposureTrack = (name: string, params?: Record<string, unknown>, urlParams?: string[]) => {
  useMount(() => {
    if (isBrowser && Array.isArray(urlParams) && !isEmpty(urlParams)) {
      const query = window.location.search;
      const queryParams = new URLSearchParams(query);
      let urlEventParams = {};

      urlParams.forEach(key => {
        urlEventParams = {
          ...urlEventParams,
          [key]: queryParams.get(key) || "",
        };
      });

      trackEvent(name, {
        ...params,
        urlEventParams,
      });
    } else {
      trackEvent(name, params);
    }
  });
};

export default useExposureTrack;
